import React from 'react'
import { Row, Result, Button, Card } from 'antd'
import { useRouter } from 'next/router'
import Head from 'next/head'
import FrownOutlined from '@ant-design/icons/FrownOutlined'

export default function NotFound() {
  const router = useRouter()
  return (
    <>
      <Head>
        <title>{`Page Not Found - ${process.env.NEXT_PUBLIC_PRODUCT_NAME}`}</title>
      </Head>
      <main style={{ width: '100vw', height: '100vh' }}>
        <Row
          style={{
            width: '100%',
            height: '100%',
            backgroundColor: process.env.NEXT_PUBLIC_PRIMARY_COLOR,
            backgroundImage: 'url(/topo3.png)',
            backgroundBlendMode: 'screen'
          }}
          justify='center'
          align='middle'
        >
          <Card
            style={{
              border: `1px solid ${process.env.NEXT_PUBLIC_PRIMARY_COLOR}`
            }}
          >
            <Result
              title='404 - Not Found'
              icon={
                <FrownOutlined
                  style={{ color: process.env.NEXT_PUBLIC_PRIMARY_COLOR }}
                />
              }
              subTitle='Sorry, the page you visited does not exist.'
              extra={
                <Button
                  type='primary'
                  size='large'
                  onClick={() => {
                    router.push('/')
                  }}
                >
                  Back Home
                </Button>
              }
            />
          </Card>
        </Row>
      </main>
    </>
  )
}
